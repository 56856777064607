import { PAGE_ID } from "stores/route/utils/types";
import userSo from "stores/user"
import { ITEMS_MAIN as ITEMS_OS } from "./tree_os"
import { ITEMS_MAIN as ITEMS_PLC } from "./tree_plc"
import farmSo from "stores/farm";

const ITEMS_MAIN = import.meta.env.VITE_TARGET == "os" ? ITEMS_OS : ITEMS_PLC



export interface MenuItem {
	/** l'ID della pagina che devono visualizzare */
	id: string
	/** Sono degli ID di pagine interne  */
	subIds?: string[]
	/** Titolo */
	title?: string
	/** gli ITEM da mostrare nel caso si prema questo */
	children?: MenuItem[]

	/** è visibile solo agli ADMIN */
	onlyAdmin?: boolean
	/** è visibile solo agli utenti DEBUG */
	onlyDebug?: boolean
	/** è disabilitato se non c'e' connessione NATS */
	onlyNats?: boolean
	/** funzione generica che nasconde una voce del menu se true */
	isHidden?: () => boolean

	/** se l'item è disabilitato o no */
	disabled?: boolean
	/** indica che l'elemento è una directory quindi va dritto dentro */
	isDir?: boolean
	/** se questo param è settato e il MenuItem selezionato visualizza solo i MenuItem dello stesso "group" */
	group?: string
	/** indica se bisogna mostrare il divider dopo questo item */
	divider?: boolean
}

/**
 * Da un PAGE_ID riesce a ricavare 
 * la sezione dove si trova, 
 * l'indice dell'item della sezione 
 * e i parent del percorso per arrivare alla sezione
 * @param pageId PAG_ID della pagina da cercare
 * @param items item dove cercare (tipicamente ITEMS_MAIN)
 * @param parents accumulatore dei parent per la ricorsione
 */
export function getByPageId(
	pageId: PAGE_ID,
	items: MenuItem[] = ITEMS_MAIN,
	parents: MenuItem[] = []
): Page {

	if (!pageId) return null
	items = items.filter(i =>
		(!i.onlyAdmin || userSo.isAdmin())
		&& (!i.onlyDebug || userSo.isDebugger())
		&& (!i.onlyNats || userSo.plcAccess())
		&& (!i.isHidden || !i.isHidden())
	)

	for (const [index, item] of items.entries()) {
		// se è questo lo restituisco
		if (eqId(item, pageId)) {
			// elimino gli item che non sono dello stesso "group"
			const sections = !item.group ? items : items.filter(i => i.group == item.group)
			const index = sections.indexOf(item)
			return { parents, sections, index }
		}
		// altrimenti se ha dei "children" controllo ricorsivo
		if (Array.isArray(item.children)) {
			const ret = getByPageId(pageId, item.children, [...parents, item])
			if (ret) return ret
		}
	}

	// nessun risultato
	return null
}
type Page = {
	parents: MenuItem[],
	sections: MenuItem[],
	index: number
}

/**
 * restituisce true se l'id passato corrisponde all'id dell ManuItem o a un suo subIds
 */
function eqId(item: MenuItem, pageId: PAGE_ID): boolean {
	return !!item.id && (item.id == pageId || item.subIds?.some(id => id == pageId))
}

/**
 * Restituisce true se l'id child è effettivamente un child nested dell id parente
 */
export function isChild(pagIdChild: PAGE_ID, pagIdParent: PAGE_ID, direct: boolean = false): boolean {
	if (!pagIdChild || !pagIdParent) return false
	const { parents } = getByPageId(pagIdChild) ?? { parents: [] }

	// se è un elemento fuori dalla TREE allora controllo direttamente
	if (pagIdChild == PAGE_ID.FARM_EXTERNAL_LINK) return true

	// se non ci sono parent e allora...
	if (parents.length == 0) return false

	// controllo solo l'ultimo
	if (direct) return eqId(parents[parents.length - 1], pagIdParent)
	return parents.some(item => eqId(item, pagIdParent))
}
