import { Channel, CHANNELS } from "plugins/ChannelService"
import growUnitSo from ".."
import { GrowUnit } from "types/GrowUnit"



let channel: Channel = null

export function startChannelRuby(growUnitId: number) {
	if (!growUnitId) return
	channel = new Channel(CHANNELS.GROWUNITS, { id: growUnitId })
	channel.subscribe(handleChannelEvents)
}

export function stopChannelRuby() {
	channel?.unsubscribe(handleChannelEvents)
}

const handleChannelEvents = (newGrowUnit: GrowUnit) => {
	const currGrowUnit:Partial<GrowUnit> = growUnitSo.state.select ?? {}
	if (currGrowUnit?.id != newGrowUnit?.id) {
		console.error("ERROR:RUBY-CHANNEL:GROW-UNIT:UPDATE:WRONG_ID")
		return
	}
	growUnitSo.setSelect({ ...currGrowUnit, ...newGrowUnit })
}