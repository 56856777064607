import { Box } from "@mui/material";
import { useStore } from "@priolo/jon";
import RangeUrlSelector from "components/RangeUrlSelector";
import PlaceholderCmp, { ICON_TYPE } from "components/controls/PlaceholderCmp";
import VerticalPage from "components/controls/page/VerticalPage";
import dayjs from "dayjs";
import { useInitFarmPage } from "pages/farms/useInitFarmPage";
import { FunctionComponent, useEffect, useMemo } from "react";
import draftSo from "stores/draft";
import { loadAll } from "stores/planner/utils/fetch";
import querySo from "stores/route/query";
import { PAGE_ID, URL_PAR } from "stores/route/utils/types";
import taskSo from "stores/task";
import detailSo from "stores/task/taskDetail";
import { getTaskFiltered } from "stores/task/utils";
import { getGroupTasksColor, getGroupTasksDescription, groupTasksByDate } from "stores/task/utils/group";
import { Task } from "types/Task";
import { dateToString, daysDuration, urlToDateRange } from "utils";
import RangeUrlCalendar from "../../../../components/RangeUrlCalendar";
import TaskDialog from "../detail/TaskDialog";
import TaskMultiEditDialog from "../multiedit/TaskMultiEditDialog";
import TaskNewDialog from "../new/TaskNewDialog";
import TasksList from "./TasksList";



const TasksListPag: FunctionComponent = () => {

	// STORE
	const taskSa = useStore(taskSo)
	useStore(querySo)
	useStore(draftSo)
	useStore(detailSo)

	// HOOKs
	useInitFarmPage(PAGE_ID.FARM_TASKS, null, async (farm) => {
		await loadAll(farm.id)
		// se non c'e' una data selezionata seleziona oggi
		const dateSel = querySo.getSearchUrl(URL_PAR.DATE_SEL)
		if (!dateSel) querySo.setSearchUrl([URL_PAR.DATE_SEL, dateToString(dayjs())])
		querySo.updateQueryUrl()
	})

	// HANDLERs
	const handleTaskSelect = (task: Task) => detailSo.open({ task })
	const handleDateClick = (day: dayjs.Dayjs) => {
		const dateSelStr = day.format("YYYY-MM-DD")
		document.getElementById(dateSelStr)?.scrollIntoView({ behavior: "smooth", block: "start" })
	}

	// RENDER
	const [cycleSel, growUnitSel, scopeSel, txtSearch, dateSel, taskUrl] = useMemo(() =>
		querySo.getSearchUrl([URL_PAR.CYCLE_SEL, URL_PAR.GROWUNIT_FIL, URL_PAR.SCOPE_FIL, URL_PAR.TEXT, URL_PAR.DATE_SEL, URL_PAR.TASK_SEL]),
		[querySo.state.queryUrl]
	)
	useEffect(() => {
		if (!!taskUrl && !!taskSo.state.all) {
			const task = taskSo.getByUuid(taskUrl)
			if (!task) return
			detailSo.open({ task })
			handleDateClick(dayjs(task.dueDate))
		}
	}, [taskSo.state.all])
	const dateRangeSel = urlToDateRange(dateSel)
	const dateSelFilter = daysDuration(dateRangeSel) == 0 ? null : dateSel
	const groups = useMemo(() => {
		return groupTasksByDate(getTaskFiltered(taskSa.all, +growUnitSel, scopeSel, cycleSel, urlToDateRange(dateSelFilter), txtSearch))
	}, [taskSa.all, cycleSel, growUnitSel, scopeSel, txtSearch, dateSelFilter])

	const renderDay = (day: dayjs.Dayjs) => {
		const group = groups.find(g => dayjs(g.date).isSame(day, "day"))
		return {
			tooltip: getGroupTasksDescription(group),
			isModify: group?.isModify,
			color: getGroupTasksColor(group?.score),
			bgcolor: group?.tasks?.length > 0 ? "rgba(255,255,255,.15)" : "#000000",
		}
	}

	return (
		<VerticalPage marginTop={12} centerSx={{ mx: "13px" }}
			onClick={(e) => {
				taskSo.setSelected([])
			}}
			renderLeft={<Box sx={{ display: "flex", flexDirection: "column" }}>
				<RangeUrlCalendar renderDay={renderDay} onClick={handleDateClick} />
				<RangeUrlSelector sx={{ ml: "19px", mt: "-15px" }} />
			</Box>}
		>
			{groups?.length > 0 ? <TasksList
				groups={groups}
				onClickTask={handleTaskSelect}
			/> : <PlaceholderCmp
				title="THERE ARE NO TASKS"
				subtitle="try changing the filter settings"
				iconType={ICON_TYPE.VOID}
			/>}

			<TaskDialog />
			<TaskMultiEditDialog />
			<TaskNewDialog />

		</VerticalPage >
	)
}

export default TasksListPag
