import { BugReport as BugIcon, Face as FaceIcon, Factory, ExitToApp as LogoutIcon, Tune as PreferencesIcon, Star } from '@mui/icons-material';
import { Box, Divider, IconButton, IconButtonProps, List, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from "@mui/material";
import { useStore } from '@priolo/jon';
import { FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { getUrlByPageId } from 'stores/route/utils/pagesId';
import { PAGE_ID } from 'stores/route/utils/types';
import userSo from 'stores/user';
import packageJson from '../../../package.json';



const UserButton: FunctionComponent<IconButtonProps> = (props) => {

	// STORE
	const userSa = useStore(userSo)

	// HOOKs
	const { t } = useTranslation()
	const anchorRef = useRef()
	const history = useHistory()


	// HANDLEs
	const handleOpen = () => userSo.setMenuOpen(true)

	const handleClose = () => userSo.setMenuOpen(false)

	const handleLogout = async () => {
		await userSo.logout({ reload: true })
	}

	const handlePreferences = () => {
		history.push(getUrlByPageId(PAGE_ID.PREFERENCES))
		userSo.setMenuOpen(false)
	}


	// RENDER
	if (!userSo.isLogged()) return null

	return <>

		<IconButton
			onClick={handleOpen}
			ref={anchorRef}
			{...props}
		>
			<FaceIcon fontSize='medium' />
		</IconButton>

		<Popover
			open={userSa.menuOpen}
			anchorEl={anchorRef.current}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
		>
			<Box sx={{ display: "flex", flexDirection: "column" }} >

				<Box sx={{ display: "flex", flexDirection: "column", margin: "20px" }} >
					<Typography color="secondary">{userSa.current.email}</Typography>
					<Box sx={{ display: "flex", alignItems: "center" }}>
						<Typography sx={{ flex: 1 }}>{userSa.current.name}</Typography>
						{userSo.isAdmin() && <Star fontSize={"16px" as any} />}
						{userSo.isDebugger() && <BugIcon fontSize={"16px" as any} />}
						{userSo.plcAccess() && <Factory fontSize={"16px" as any} />}
					</Box>
				</Box>

				<Divider />

				<Box sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
					<List>
						<ListItemButton onClick={handlePreferences}>
							<ListItemIcon><PreferencesIcon /></ListItemIcon>
							<ListItemText primary={t("dialog.user.preferences")} />
						</ListItemButton>
						<ListItemButton onClick={handleLogout}>
							<ListItemIcon><LogoutIcon /></ListItemIcon>
							<ListItemText primary={t("dialog.user.logout")} />
						</ListItemButton>
					</List>
					<Box sx={{ alignSelf: "end", margin: "0px 20px 10px 0px" }}>
						<Typography
							variant="caption"
						>{packageJson.version}</Typography>
					</Box>
				</Box>

			</Box>
		</Popover>

	</>
}

export default UserButton
