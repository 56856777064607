import { StoreCore, addWatch, createStore, mixStores } from "@priolo/jon"
import draftApi from "api/draft"
import solApi from "api/productions_solution"
import { TAGS } from "components/planner/types"
import { activitiesGetOverlap, getActivityFromGroups } from "components/planner/utils"
import i18n from "i18next"
import { CHANNELS, Channel } from "plugins/ChannelService"
import cycleSo from "stores/cycle"
import farmSo from "stores/farm"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import plannerSo from "stores/planner"
import prodSo from "stores/productions"
import querySo from "stores/route/query"
import { URL_PAR } from "stores/route/utils/types"
import taskSo from "stores/task"
import { DRAFT_STATUS, Draft } from "types/Draft"
import { Farm } from "types/Farm"
import { Solution } from "types/Production"
import cyclesSetup from "./cycles"
import sessionsSetup from "./sessions"
import tasksSetup from "./tasks"
import { DRAFT_STATUS_FILTER, SESSION_TYPE, sessionFromDraftId, sessionsDraftsStorageWrite } from "./utils"



const isPLC = import.meta.env.VITE_TARGET == "plc"

const setup = {

	state: {
		/** dialog della lista di tutti i DRAFTs */
		draftsOpen: false,
		draftFilter: DRAFT_STATUS_FILTER.CURRENTS,
		draftEditOpen: false,
		draftInEdit: <Draft>null,
		drafts: <Draft[]>null
	},

	getters: {
		/** restituisce un DRAFT tramite il suo id */
		getDraftById: (draftId: number, store?: DraftStore) => store.state.drafts?.find(d => d.id == draftId),
		getDraftIndexById: (draftId: number, store?: DraftStore) => store.state.drafts?.findIndex(d => d.id == draftId),
	},

	actions: {

		/** carico la lista dei DRAFTS */
		async fetchDrafts(_: void, store?: DraftStore) {
			const farmId = farmSo.state.select?.id
			// carico l'ultimo DRAFT-ACTIVE
			let drafts: Draft[] = (await draftApi.index(farmId))?.data
			store.setDrafts(drafts ?? [])
		},
		async fetchDraftsIfVoid(_: void, store?: DraftStore) {
			if (store.state.drafts != null) return
			await store.fetchDrafts()
		},

		/** 
		 * creo un nuovo DRAFT dalla PROD e la metto in lista DRAFTS 
		 * cioe' quando premo NEW DRAFT nella TAB PROSUZONE
		*/
		async createDraftFromProd(desciption: string, store?: DraftStore) {
			const farmId = farmSo.state.select.id
			if (!farmId || !desciption?.length) return
			const draft = (await draftApi.create(farmId, desciption))?.data;
			store.setDrafts([...store.state.drafts, draft])
			// inserisco il DRAFT in SESSIONS
			const session = sessionFromDraftId(draft.id)
			store.addSession(session)
			store.selectSession(store.getSessionIndex(session))
		},

		/** invio al BE una modifica del DRAFT
		 * in realta' posso modificare solo "description"
		*/
		async modifyDraft(draft: Draft, store?: DraftStore) {
			const index = store.getDraftIndexById(draft.id)
			const farmId = farmSo.state.select?.id
			if (!farmId || index == -1 || !draft) return
			await draftApi.update(farmId, draft.id, { description: draft.description })
			store.state.drafts[index] = { ...store.state.drafts[index], ...draft }
			store.setDrafts([...store.state.drafts])
		},

		/** creo un DRAFT da una SOLUTION */
		async createDraftFromSolution(
			{ solution, description }: { solution: Solution, description: string },
			store?: DraftStore) {
			const production = prodSo.getByReqUuid(solution?.requestUuid)
			if (!production) return
			const { data: draft } = await solApi.promote(production, solution.uuid, description)
			store.setDrafts([...store.state.drafts, draft])
			return draft
		},

		/** applica il CURRENT DRAFT-SESSION alla PROD 
		 * cioe' quando premo APPLY sul TAB DRAFT
		*/
		async applyDraftInProd(_: void, store?: DraftStore) {
			// controllo he non ci siano ACTIVITY in overlap
			const actOverlaps = activitiesGetOverlap(getActivityFromGroups(plannerSo.state.groups))
			if (actOverlaps.length > 0) {
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.WARNING,
					title: i18n.t(`dialog.draft.overlap.title`),
					text: i18n.t(`dialog.draft.overlap.text`),
					labelCancel: null,
					labelOk: i18n.t(`dialog.draft.overlap.ok`),
				})
				const cycleUuid = actOverlaps[0].tags?.[TAGS.CYCLES]?.[0]
				if (!!cycleUuid) {
					querySo.setSearchUrl([URL_PAR.CYCLE_SEL, cycleUuid])
					plannerSo.showActivities([actOverlaps[0]])
				}
				return false
			}
			// chiedo conferma
			const resp = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				title: i18n.t(`dialog.draft.apply.title`),
				text: i18n.t(`dialog.draft.apply.text`),
				labelCancel: i18n.t(`dialog.draft.apply.cancel`),
				labelOk: i18n.t(`dialog.draft.apply.ok`),
				labelConfirm: i18n.t(`dialog.draft.apply.confirm`),
			})
			if (!resp) return false

			const session = store.getSessionSelect()
			const draftId = session?.draftId
			const farmId = farmSo.state.select?.id
			if (!farmId || draftId == null || session.type == SESSION_TYPE.PROD) return
			taskSo.setSelected([])

			// invio al BE
			await draftApi.apply(farmId, draftId)
			// recupera tutti i CYCLEs della FARM in produzione
			await cycleSo.fetch({ farmId, force: true })
			// recupera tutti i TASKs della FARM in produzione
			await taskSo.fetch({ farmId, force: true })
			// segno il daft selezonato a APPLY
			const draft = store.getDraftById(draftId)
			if (!draft) return false

			// aggiornamento
			draft.status = DRAFT_STATUS.APPLYING
			store.setSessions(store.state.sessions.filter(s => s.draftId != draft.id))
			store.selectSession(0)

			//updatePlannerGroups()
			dialogSo.dialogOpen({ type: DIALOG_TYPE.INFO, modal: false, text: i18n.t("snackbar.default.save") })
			return true
		},

		/** aggiorno un draft gia' presente */
		async updateDraft(draft: Draft, store?: DraftStore) {
			const index = store.state.drafts.findIndex(d => d.uuid == draft.uuid)
			if (index == -1) return
			// aggiorno i DRAFTS
			store.state.drafts[index] = { ...store.state.drafts[index], ...draft }
			store.setDrafts([...store.state.drafts])
			// aggiorno la SESSION che visualizza il DRAFT
			const session = store.getSessionByDraftId(draft.id)
			if (session) session.cycle = session.task = null
			// aggiorno il PLANNER se le SESSION è selezionata
			if (store.getSessionSelect()?.draftId == draft.id) {
				await store.selectSession()
			}
		},

		/** cancella definitivamente un DRAFT */
		async deleteDraft(_: void, store?: DraftStore) {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				title: i18n.t("dialog.draft.delete.title"),
				text: i18n.t("dialog.draft.delete.text"),
				labelOk: i18n.t("dialog.draft.delete.ok"),
			})
			if (!res) return
			const farmId = farmSo.state.select?.id
			const session = store.getSessionSelect()
			const draft = store.getDraftById(session?.draftId)
			if (!draft || !farmId) return
			await draftApi.remove(farmId, draft.id)
			store.delSession(store.getSessionIndex(session))
			store.setDrafts(store.state.drafts.filter(d => d.id != draft.id))
		}
	},

	mutators: {
		setDraftsOpen: (draftsOpen: boolean) => ({ draftsOpen }),
		setDraftFilter: (draftFilter: DRAFT_STATUS_FILTER) => ({ draftFilter }),
		setDraftEditOpen: (draftEditOpen: boolean) => ({ draftEditOpen }),
		setDraftInEdit: (draftInEdit: Draft) => ({ draftInEdit }),
		setDrafts: (drafts: Draft[]) => ({ drafts }),
	},
}

export type DraftState = typeof setup.state & typeof sessionsSetup.state
export type DraftGetters = typeof setup.getters & typeof sessionsSetup.getters & typeof cyclesSetup.getters
export type DraftActions = typeof setup.actions & typeof sessionsSetup.actions & typeof tasksSetup.actions & typeof cyclesSetup.actions
export type DraftMutators = typeof setup.mutators & typeof sessionsSetup.mutators
export interface DraftStore extends StoreCore<DraftState>, DraftGetters, DraftActions, DraftMutators {
	state: DraftState
}
const draftSo = createStore(mixStores(sessionsSetup, cyclesSetup, tasksSetup, setup)) as DraftStore
export default draftSo

let channel: Channel = null
let oldFarmId = null



addWatch({
	store: farmSo,
	actionName: "setSelect",
	callback: (value) => {
		// se è la versione PLC non fare nulla
		if (isPLC) return

		const farm = value?.payload as Farm

		window.removeEventListener("beforeunload", handleUnload)
		sessionsDraftsStorageWrite(oldFarmId, draftSo.state.sessions)
		draftSo.clearSessions()

		// channel RUBY
		if (channel) {
			channel?.unsubscribe(handleDraftChannel)
			channel = null
		}
		if (!!farm) {
			channel = new Channel(CHANNELS.DRAFT, { farm_id: farm.id })
			channel.subscribe(handleDraftChannel)
			window.addEventListener("beforeunload", handleUnload)
			//debug()
		}

		oldFarmId = farm?.id
	}
})

const handleDraftChannel = (draft: Draft) => {
	draftSo.updateDraft(draft)
}

const handleUnload = () => {
	const farmId = farmSo.state.select?.id
	sessionsDraftsStorageWrite(farmId, draftSo.state.sessions)
}

// function debug() {
// 	setTimeout(() => {
// 		const draft = store.state.drafts.find(d => d.status == DRAFT_STATUS.PROMOTING_FROM_SOLUTION)
// 		handleDraftChannel({
// 			...draft,
// 			status: DRAFT_STATUS.PROMOTED_FROM_SOLUTION,
// 		})
// 	}, 5000)
// }