import { PAGE_ID } from "stores/route/utils/types"
import { MenuItem } from "./utils"
import { COMPONENT_TYPE } from "types/Service"
import i18n from "i18next"
import farmSo from "stores/farm"



/**
 * gli items sotto la sezione FARM
 */
const ITEMS_GROWUNIT: MenuItem[] = [
	{ id: PAGE_ID.GROWUNIT_HOME, title: i18n.t("menu.home.label"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_MAIN, title: i18n.t("menu.main.label"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_LAYERS, title: i18n.t("menu.farms.gu.layers"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_FERTI, title: i18n.t("menu.farms.gu.actions"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_ADJ, title: i18n.t("menu.farms.gu.adj"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_LIGHTING, title: i18n.t("menu.farms.gu.lighting"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_LIGHTING_PS, title: i18n.t("menu.farms.gu.lighting_ps"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.GROWUNIT_IRRIGATION, title: i18n.t("menu.farms.gu.irrigation"), group: COMPONENT_TYPE.GROWUNIT },
	{ id: PAGE_ID.COMPONENT_HVAC, title: i18n.t("menu.farms.gu.hvac"), group: COMPONENT_TYPE.HVAC },
	{ id: PAGE_ID.COMPONENT_HVAC_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.HVAC },
	{ id: PAGE_ID.COMPONENT_GERMINATION, title: i18n.t("menu.farms.gu.germinator"), group: COMPONENT_TYPE.GERMINATOR },
	{ id: PAGE_ID.COMPONENT_GERMINATION_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.GERMINATOR },	
	{ id: PAGE_ID.COMPONENT_OSMOTIC_WATER, title: "MAIN", group: COMPONENT_TYPE.OSMOTIC_WATER },
	{ id: PAGE_ID.COMPONENT_OSMOTIC_WATER_ALERTS, title: "PLC WARNING", group: COMPONENT_TYPE.OSMOTIC_WATER },
]

/**
 * ITEM-MENU visualizzati sul MAIN-MENU
 */
export const ITEMS_MAIN: MenuItem[] = [
	{ id: PAGE_ID.FARM_MAP, title: "HOME", 
		isHidden: () => !farmSo.state.select?.mapConfig,
	},
	{ id: PAGE_ID.FARM_GROWUNITS, title: i18n.t("menu.farms.gu.label"), children: ITEMS_GROWUNIT },
	{ id: PAGE_ID.FARM_MODULES, title: i18n.t("menu.modules.label") },
	{ id: PAGE_ID.FARM_METADATA, title: i18n.t("menu.metadata.label") },
	{ id: PAGE_ID.COMPONENT_POWER_MONITOR, title: "POWERS",
		isHidden: () => farmSo.getComponentsByType( COMPONENT_TYPE.POWERS).length == 0
	 },
	{ id: PAGE_ID.FARM_PLC_ALERTS, title: "PLC WARNING" },
]
