import { Component, GrowUnitComponent } from "types/Service"



/**
 * filtra i COMPONENTS
 */
export function getFiltered(components: Component[], txt?: string): Component[] {
	if (!components) return []
	txt = txt?.trim()?.toLowerCase()
	const component = components.filter(component => {
		if ( component.hidden == true ) return false
		if (!(txt?.length > 0)) return true
		if (component.code?.toLowerCase().includes(txt)) return true
		if ((component as GrowUnitComponent)?.options?.code?.toLowerCase()?.includes(txt)) return true
		return false
	})
	return component
}
