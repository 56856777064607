
/**
 * Tipologie di GROW-UNIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-24455?block=block-ee83152d-3a06-4b89-b2c2-11f00aff2b08
 */
export enum GROW_UNIT_TYPOLOGY {
	MANUAL = "M",
	SMALL = "S",
	LARGE = "L",
	CANNABIS = "C",
	LAB = "X",
	CONTAINER = "R",
	GERMINATION = "G",
	ZR11_VBS = "ZR11_VBS",
	ZR13_RUH = "ZR13_RUH",
	ZR14_AUH = "ZR14_AUH",
	ZR18_LPK = "ZR18_LPK",
}

/**
 * Stato delle GROW-UNIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-24455?block=block-186a257f-965e-4c48-ad5e-717953c64630
 */
export enum GROW_UNIT_STATUS {
	IDLE = "idle",
	RUNNING = "running",
	PAUSED = "paused",
	UNKNOW = "unknow",
	WAITING = "waiting",
	OFFLINE = "offline",
}

/**
 * TIPO di Azioni che è possibile fare
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-24455?block=block-d214357b-1e94-4f79-85bb-1dfd687dddb3
 */
export enum GROW_UNIT_ACTION {
	PLAN = "plan",
	PLAY = "play",
	PAUSE = "pause",
	REWIND = "rewind",
	JUMP = "jump_day",
}

/**
 * Modalità della GROW-UNIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-24455?block=block-8a7ba56a-9e76-4bf9-a9c6-a843e45ec315
 */
export enum GROW_UNIT_MODE {
	HUMAN = "human",
	ROOT = "root",
}
