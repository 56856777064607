import { StoreCore, StoreSetup, createStore, validateAll } from "@priolo/jon";
import alarmsApi from "api/alarms";
import i18n from "i18next";
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore";
import querySo from "stores/route/query";
import { ALARM_SEVERITY, Alarm, AlarmOverride } from "types/AlertRule";

/**
 * Gestione lista di ALARMs e dettaglio
 */

const setup = {

	state: {
		// All ALARMs (used in index page)
		all: <Alarm[]>[],
		// Alarm select
		// https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-259/ALARMS
		select: <Alarm>null,
		selectOldCode: <string>null,
	},

	getters: {
		getFiltered: (_: void, store?: AlarmStore): Alarm[] => {
			let txt = querySo.getSearchUrl("search")
			let items = store.getFilteredExt(txt)
			items = querySo.getSorted({
				items,
				map: { "description": alarm => store.getDescription(alarm) }
			})
			return items
		},
		getFilteredExt: (text: string, store?: AlarmStore): Alarm[] => {
			let alarms = [...(store.state.all ?? [])]
			const txt = text?.trim().toLowerCase()
			if (!txt || txt.length == 0) return alarms
			alarms = alarms.filter(alarm => {
				if (alarm.alarmCode.toLowerCase().includes(txt)) return true
				if (store.getDescription(alarm)?.toLowerCase().includes(txt)) return true
				return false
			})
			return alarms
		},
		getByCode: (code: string, store?: AlarmStore): Alarm => {
			return !!code ? store.state.all.find(alarm => alarm.alarmCode == code) : null
		},
		getAlarmOverride: (index: number, store?: AlarmStore): AlarmOverride => {
			return index == -1 ? store.state.select.management.default : store.state.select.management.typologyOverrides[index]
		},
		getTypoOverridesLabel: (alarm: Alarm, store?: AlarmStore): string => {
			if (!alarm) return ""
			const typos = alarm.management.typologyOverrides
				.map(over => over.typologiesOverrided
					.map(typology => i18n.t(`app.farm.typology.${typology}`)))
				.flat()
			return typos.join(", ")
		},
		getDescription: (alarm: Alarm, store?: AlarmStore): string => {
			return alarm.management.default.description ?? ""
		},
		getOverrideId: (override: AlarmOverride, store?: AlarmStore): number => {
			if (!override["_id"]) override["_id"] = Math.round(Math.random() * 9999).toString()
			return override["_id"]
		}
	},

	actions: {
		fetchAll: async (_: void, store?: AlarmStore) => {
			const { data } = await alarmsApi.index()
			store.setAll(data)
		},
		fetchAllIfVoid: async (_: void, store?: AlarmStore) => {
			if (store.state.all?.length > 0) return
			await store.fetchAll()
		},
		fetchSelect: async (code: string, store?: AlarmStore) => {
			await store.fetchAllIfVoid()
			const alarm = store.getByCode(code)
			store.setSelect(alarm)
			store.setSelectOldCode(code)
		},
		fetchSelectAndClone: async (code: string, store?: AlarmStore) => {
			await store.fetchAllIfVoid()
			const alarm = store.getByCode(code)
			store.setSelect(alarm)
			store.setSelectOldCode(null)
		},
		editNew: async (_: void, store?: AlarmStore) => {
			const newAlarm = {
				alarmCode: "",
				management: {
					default: {
						severity: ALARM_SEVERITY.MODERATE,
						teams: [],
						taskTemplateGroupUuid: null,
					},
					typologyOverrides: [],
				},
			}
			store.setSelect(newAlarm)
			store.setSelectOldCode(null)
		},
		addOverride: (_: void, store?: AlarmStore) => {
			const base = store.state.select.management.default
			const override = {
				...base,
				typologiesOverrided: [],
				_id: null,
				collapsed: false
			}
			store.state.select.management.typologyOverrides.push(override)
			store.setSelect({ ...store.state.select })
		},
		removeOverride: (index: number, store?: AlarmStore) => {
			store.state.select.management.typologyOverrides.splice(index, 1)
			store.setSelect({ ...store.state.select })
		},
		cloneOverride: (index: number, store?: AlarmStore) => {
			const overrides = store.state.select.management.typologyOverrides
			const override = { ...overrides[index], typologiesOverrided: [], _id: null, collapsed: false }
			overrides.splice(index + 1, 0, override)
			store.setSelect({ ...store.state.select })
		},
		save: async (_: void, store?: AlarmStore) => {
			// validazione dei dati inseriti
			const warn = validateAll()
			if (warn.length > 0) {
				warn[0].ref?.focus()
				dialogSo.dialogOpen({
					type: DIALOG_TYPE.WARNING, labelCancel: null,
					text: i18n.t("dialog.alarm.warn-save")
				})
				return false
			}

			const alarm = { ...store.state.select }
			if (store.state.selectOldCode != null && store.state.selectOldCode != alarm.alarmCode) {
				alarm.newAlarmCode = alarm.alarmCode
				alarm.alarmCode = store.state.selectOldCode
				store.setSelectOldCode(null)
			}
			alarm.management.typologyOverrides = alarm.management.typologyOverrides.map(override => {
				delete override["collapsed"]
				delete override["_id"]
				return override
			})

			await alarmsApi.manage(alarm)

			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t(`snackbar.alarm.update`)
			})
			return true
		},
		destroy: async (code: string, store?: AlarmStore) => {
			const res = await dialogSo.dialogOpen({
				type: DIALOG_TYPE.WARNING,
				text: i18n.t("dialog.alarm.delete"),
			})
			if (res == false) return
			await alarmsApi.destroy(code)
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.INFO, modal: false,
				text: i18n.t("snackbar.alarm.delete"),
			})
			const alarms = store.state.all.filter(alarm => alarm.alarmCode != code)
			store.setAll(alarms)
		},
	},
	mutators: {
		setAll: (all: Alarm[]) => ({ all }),
		setSelect: (select: Alarm) => ({ select }),
		setSelectOldCode: (selectOldCode: string) => ({ selectOldCode }),
		setSelectProp: (prop: Alarm, store?: AlarmStore) => ({ select: { ...store.state.select, ...prop } }),
		setAlarmOverride: (
			{ index, name, value }: { index: number, name: string, value: any },
			store?: AlarmStore
		) => {
			const override = store.getAlarmOverride(index)
			override[name] = value
			return { select: { ...store.state.select } }
		},
	},
}



export type AlarmState = typeof setup.state
export type AlarmGetters = typeof setup.getters
export type AlarmActions = typeof setup.actions
export type AlarmMutators = typeof setup.mutators
export interface AlarmStore extends StoreCore<AlarmState>, AlarmGetters, AlarmActions, AlarmMutators {
	state: AlarmState
}
const alarmSo = createStore(setup) as AlarmStore
export default alarmSo
