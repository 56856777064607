import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, Card, Collapse, SxProps, Typography } from '@mui/material';
import { useStore } from '@priolo/jon';
import React, { FunctionComponent, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import deviceSo from 'stores/layout/device';
import theme from 'styles/theme';



interface Props extends HTMLAttributes<HTMLElement> {
	icon?: React.ReactNode
	title?: any
	titleVar?: any
	endRender?: React.ReactNode
	compact?: boolean
	collapsed?: boolean
	selectable?: boolean

	onCollapsedChange?: (collapsed: boolean) => void
	onTitleClick?: (e: React.MouseEvent) => void
	onTitleVisibleChange?: (visible: boolean) => void

	children?: ReactNode
	sx?: SxProps
	id?: any
}

const FormParagraph: FunctionComponent<Props> = ({
	id,
	icon,
	title,
	titleVar = "h6",
	endRender,
	compact,
	collapsed,
	selectable,

	onCollapsedChange,
	onTitleClick,
	onTitleVisibleChange,

	children,
	sx,
	...props
}) => {

	// STATE
	useStore(deviceSo)


	// HOOKs
	const titleRef = useRef<HTMLElement>(null)

	useEffect(() => {
		if (!id || !selectable) return
		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry.isIntersecting && deviceSo.state.linkIdSelect == id) {
					deviceSo.setLinkIdSelect(null)
				}
			},
			{ root: null, threshold: 0 }
		)
		if (titleRef.current) observer.observe(titleRef.current)
		return () => {
			if (titleRef.current) observer.unobserve(titleRef.current)
		}
	}, [])

	// HANDLER
	const handleIconClick = (e: React.MouseEvent) => {
		e.stopPropagation()
		onCollapsedChange?.(!collapsed)
	}
	const handleTitleClick = (e) => {
		if (selectable && deviceSo.state.linkIdSelect != id) deviceSo.setLinkIdSelect(id)
		if (!onTitleClick) {
			onCollapsedChange?.(!collapsed)
			return
		}
		if (collapsed) onCollapsedChange?.(false)
		onTitleClick?.(e)
	}

	// RENDER
	const isCollapsable = !!onCollapsedChange
	if (isCollapsable) icon = collapsed ? <KeyboardArrowUp /> : <KeyboardArrowDown />
	const mh = compact ? "5px" : "15px"
	const styTitle: SxProps = {
		display: "flex",
		alignItems: "center",
		cursor: isCollapsable || !!props.onClick || !!onTitleClick ? "pointer" : "default",
		mb: isCollapsable ? 0 : mh
	}
	const sxSelected: SxProps = selectable && deviceSo.state.linkIdSelect == id ? sxSelect : null

	return <Card
		sx={[sxCard, sxSelected, sx] as any}
		id={id}
		{...props}
	>

		{/* TITLE */}
		{title && <Box
			sx={styTitle}
			onClick={handleTitleClick}
			ref={titleRef}
		>

			{icon && <Box
				sx={{ display: "flex", mr: "10px", mt: "-3px" }}
				onClick={handleIconClick}
			>{icon}</Box>}

			<Typography variant={titleVar}>
				{title}
			</Typography>

			<Box sx={{ flex: 1 }} />

			{endRender}

		</Box>}

		{!isCollapsable ? children :
			<Collapse in={!collapsed} timeout="auto">
				<Box sx={{ mb: mh }} />
				{children}
			</Collapse>
		}
	</Card>
}

export default FormParagraph

const sxCard: SxProps = {
	display: "flex",
	flexDirection: "column",
	padding: "16px 20px 16px 20px",
	borderRadius: "10px",
}

const sxSelect: SxProps = {
	//outline: "3px solid rgba(255,255,255,.2)",
	outline: `2px solid ${theme.palette.secondary.main}`,
}