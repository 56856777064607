import dayjs from 'dayjs'
import ajax from "plugins/AjaxService"
import { Channel } from 'plugins/ChannelService'
import { Cycle } from "types/Cycle"
import { Layer } from "types/Layer"
import { DateRange, DateString, Uuid } from "types/global"
import { dateRangeToDateRangeString } from 'utils'


// url builder
const urlFarmBuilder = (farmId: number, cycleId?: number): string => `farms/${farmId}/cycles${cycleId ? `/${cycleId}` : ""}`
const urlGrowUnitBuilder = (growUnitId: number, cycleId?: number): string => `grow_units/${growUnitId}/cycles${cycleId ? `/${cycleId}` : ""}`

/**
 * INDEX (FARM)
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28095
 */
function indexByFarm(farmId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = urlFarmBuilder(farmId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * INDEX (GROW UNIT)
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-222/index
 */
function indexByGrowUnit(growUnitId: number, range?: DateRange) {
    const url = urlGrowUnitBuilder(growUnitId)
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return ajax.get(`${url}?${query}`)
}

/**
 * SHOW
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28115
 */
function get(farmId: number, cycleId: number): Promise<{ data: Cycle }> {
    const url = urlFarmBuilder(farmId, cycleId)
    return ajax.get(url)
}

/**
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-25595
 */
function harvestCropLot(lotCode: string, yieldAmount: number, unitsYield: number) {
    return ajax.post(`cycles/yield_by_lot_code`, { lotCode, yieldAmount, unitsYield })
}

/**
 * https://airilabs.myjetbrains.com/youtrack/articles/ZOS-A-303/delay
 */
async function modifyByFarm(farmId: number, data: { cycles: Partial<Cycle>[] }): Promise<{ data: Cycle[] }> {
    const result = await ajax.post(`${urlFarmBuilder(farmId)}/delay`, data)
    return result
}



//#region DRAFT

/**
 * CREATE
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28275
 */
async function defineDraft(cycle: DefineDataDraft, draftId: number, draftUuid: string): Promise<{ data: Cycle }> {
    return wrapChannelConfirm<{ data: Cycle }>(
        `cycle_#${draftUuid}`,
        () => ajax.post(`cycles/cycles_drafts/${draftId}/define`, { cycle })
    )
}
export interface DefineDataDraft {
    growUnitId: number
    cropCycleId: number
    seedingDate: DateString
    phaseLayers: Partial<Layer>[]
}

/**
 * INDEX
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-28315
 */
async function indexDraft(draftId: number, range?: DateRange): Promise<{ data: Cycle[] }> {
    const url = `cycles/cycles_drafts/by_draft/${draftId}`
    const r = dateRangeToDateRangeString(range)
    const query = `start_date=${r.start}&end_date=${r.end}`
    return await ajax.get(`${url}?${query}`)
}

/**
 * BULK EDIT
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29275
 */
async function bulkScheduleDraft(data: CycleDraftData, draftId: number, draftUuid: string): Promise<{ data: Cycle[] }> {
    return wrapChannelConfirm<{ data: Cycle[] }>(
        `cycle_#${draftUuid}`,
        () => ajax.post(`cycles/cycles_drafts/${draftId}/bulk_schedule`, data)
    )
}
export type CycleDraftData = {
    scheduled?: Cycle[],
    rejected?: { refToCycleUuid: Uuid }[],
    undo?: { refToCycleUuid: Uuid }[],
}

/**
 * si mette in ascolto su ACTION-CABLE
 * effettuo la chiamata HTTP
 * attendo il segnale di avvenuta creazione
 * se non arriva entro TOT secondi, forzo la risoluzione
 */
function wrapChannelConfirm<T = any>(topic: string, callback: () => Promise<any>): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {

        // attesa dell'arrivo del segnale di avvenuta creazione
        const channel = new Channel(topic)
        let idTimeout: any = null
        const handleRequestChannel = () => {
            clearTimeout(idTimeout)
            channel?.unsubscribe(handleRequestChannel)
            resolve(result)
        }
        channel.subscribe(handleRequestChannel)

        // esecuzione della chiamata
        const result = await callback()

        // se sono in DEV non aspetto proprio un bel niente!
        if (import.meta.env.DEV) {
            handleRequestChannel()
        } else {
            // se non arriva il segnale in 5 secondi, forzo la risoluzione
            idTimeout = setTimeout(handleRequestChannel, 1000)
        }
    })
}

//#endregion








const api = {
    //index,
    indexByFarm,
    indexByGrowUnit, // USATO IN LAYERS/TOWERS
    get,
    //current,
    //harvest,
    //reject,
    //delay,
    //modify,
    modifyByFarm, // DA ELIMINARE
    //define,
    //validateOverlaps,
    harvestCropLot,


    //#region DRAFT
    defineDraft,
    indexDraft,
    bulkScheduleDraft,
    //#endregion
}
export default api
