import { getGrowUnitName } from "stores/growUnit/utils";
import { Farm } from "types/Farm";
import { GROW_UNIT_TYPOLOGY } from "types/GrowUnitEnum";
import { COMPONENT_TYPE, GrowUnitComponent } from "types/Service";


/**
 * compone i COMPONENTS della FARM in base ai dati che dispongo
 */
export function normalizeFarm(farm: Farm) {
	const config = farm.edgeServicesConfig ?? { components: [] }

	farm.growUnit.forEach(growUnit => {

		// se il GROWUNIT è già presente non lo aggiungo
		if (config.components.some(component =>
			component.type == COMPONENT_TYPE.GROWUNIT &&
			(<GrowUnitComponent>component).options.code == growUnit.growUnitCode
		)) return

		// se è di tipo GERMINATOR non lo aggiungo
		if (growUnit.typology == GROW_UNIT_TYPOLOGY.GERMINATION) return

		// altrimenti lo aggiungo
		const growUnitCmp: GrowUnitComponent = {
			name: getGrowUnitName(growUnit),
			code: growUnit.growUnitCode,
			type: COMPONENT_TYPE.GROWUNIT,
			options: {
				code: growUnit.growUnitCode,
				fans: [],
			}
		}
		config.components.push(growUnitCmp)
	})
	farm.edgeServicesConfig = config
	return farm
}