import ajax from "plugins/AjaxService"


/**
 * retrieves all ALARMS
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29195
 */
const index = () => ajax.get("alarms")

/**
 * modify or create ALARM
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29215
 */
const manage = alarm => ajax.post("alarms/manage", alarm)

/**
 * delete a specific ALARM
 * https://app.clickup.com/30373227/v/dc/wyxbb-20075/wyxbb-29235
 */
const destroy = alarmCode => ajax.delete("alarms/manage", { alarmCode } )


const api = {
	index,
	manage,
	destroy,
}
export default api